<template>
	<div class="order_wrap">
		<v-container class="">
			<div class="message_box mt-5">
				<h3 class="d1 my-5"><strong>안내</strong></h3>
				<span
					>※ 현재 파일럿 테스트 중입니다.
					<br />
					정식 배포 후 이용 부탁드립니다.
				</span>
			</div>
		</v-container>
	</div>
</template>

<script>
export default {
	components: {},
	props: {},
	data() {
		return {}
	},
	methods: {}
}
</script>

<style scoped lang="scss"></style>
